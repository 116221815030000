<template>
  <v-layout>
    <v-app-bar flat class="appBar px-md-4" prominent>
      <v-app-bar-nav-icon
        v-if="isTablet"
        variant="text"
        class="ml-0 appBar__navIcon"
        @click.stop="drawer = !drawer"
      />

      <v-app-bar-title class="appBar__title">
        <NuxtLink :to="localePath('/')">
          <Logo />
        </NuxtLink>
      </v-app-bar-title>

      <v-spacer class="ml-auto" />

      <AvatarDropdown />
    </v-app-bar>

    <v-footer class="footer justify-center" app>
      {{ t('common.copyright', { year }) }}
    </v-footer>

    <v-navigation-drawer v-model="drawer" mobile-breakpoint="sm">
      <v-list v-model:opened="opened">
        <template v-for="item in navItems" :key="item.to">
          <template v-if="item.child">
            <v-list-group :value="item.name">
              <template #activator="{ props }">
                <v-list-item v-bind="props" :data-cy="'menu-' + item.name">
                  <v-list-item-title>
                    {{ $t(item.translationKey) }}
                  </v-list-item-title>
                </v-list-item>
              </template>

              <NuxtLink
                v-for="subtitem in item.child"
                :key="subtitem.to"
                :to="localePath(subtitem.to!)"
                class="navigation__link"
              >
                <v-list-item
                  :value="subtitem.name"
                  color="primary"
                  :active="route.fullPath === subtitem.to"
                  :data-cy="'menu-' + subtitem.name"
                >
                  <v-list-item-title>
                    {{ $t(subtitem.translationKey) }}
                  </v-list-item-title>
                </v-list-item>
              </NuxtLink>
            </v-list-group>
          </template>
          <template v-else>
            <NuxtLink :to="localePath(item.to!)" class="navigation__link">
              <v-list-item
                :value="item.name"
                color="primary"
                :active="route.fullPath === item.to"
                :data-cy="'menu-' + item.name"
              >
                <v-list-item-title>
                  {{ $t(item.translationKey) }}
                </v-list-item-title>
              </v-list-item>
            </NuxtLink>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <div class="px-6 py-4">
        <slot />
      </div>
    </v-main>
  </v-layout>
</template>

<script setup lang="ts">
import { useCssBreakpoints } from '@/composables/useCssBreakpoints';
import { menuItems } from '@/constants/menu';
import type { MenuItem } from '@/types/global';
import { useUserStore } from '@/store/user';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';

const localePath = useLocalePath();
const { hasRequiredPathPermission } = usePermissionUtils();
const route = useRoute();
const userStore = useUserStore();
const { t, setLocale } = useI18n();

const drawer = ref(true);
const opened = ref<string[]>([]);
const navItems = ref<MenuItem[]>([]);

const { isTablet } = useCssBreakpoints();

const year = computed(() => format(new Date(), 'yyyy'));

const findOpened = (path: string, items: MenuItem[]): string[] => {
  for (const item of items) {
    if (item.to === path) {
      return [item.name];
    }

    if (item.child) {
      const childOpened = findOpened(path, item.child);

      if (childOpened.length > 0) {
        return [item.name];
      }
    }
  }

  return [];
};

onMounted(() => {
  navItems.value = cloneDeep(menuItems).filter((item) => {
    if (item.child) {
      item.child = item.child.filter((subitem) =>
        hasRequiredPathPermission(subitem.name),
      );

      if (item.child.length === 0) {
        return false;
      }
    }

    return hasRequiredPathPermission(item.name);
  });

  opened.value = findOpened(route.fullPath, navItems.value);

  drawer.value = !isTablet.value;

  setLocale(userStore.language);
});

watch(
  () => userStore.language,
  (newLanguage, oldLanguage) => {
    if (newLanguage !== oldLanguage) {
      setLocale(newLanguage);
    }
  },
);
</script>

<style lang="scss" scoped>
.appBar.v-toolbar.v-app-bar {
  background: rgb(var(--v-app-bar-background));
}
.navigation__link {
  text-decoration: none;
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
}
.appBar__navIcon {
  color: rgba(var(--v-theme-surface), var(--v-high-emphasis-opacity));
}
.footer.v-footer {
  background: rgb(var(--v-app-bar-background));
  color: rgba(var(--v-theme-surface), var(--v-high-emphasis-opacity));
  font-size: 0.875rem;
}
</style>
