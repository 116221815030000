import { ProtectedPath } from '@/constants/protectedPath';
import { Routes } from '@/constants/routes';
import type { MenuItem } from '@/types/global';

const appPath = `/${ProtectedPath.APP}`;

export const menuItems: MenuItem[] = [
  {
    translationKey: 'layout.default.enums',
    name: `${ProtectedPath.APP}-enums`,
    child: [
      {
        translationKey: 'layout.default.resources',
        name: `${ProtectedPath.APP}-${Routes.Resources}`,
        to: `${appPath}/${Routes.Resources}`,
      },
      {
        translationKey: 'layout.default.elementaryActivities',
        name: `${ProtectedPath.APP}-${Routes.ElementaryActivities}`,
        to: `${appPath}/${Routes.ElementaryActivities}`,
      },
      {
        translationKey: 'layout.default.compositeActivities',
        name: `${ProtectedPath.APP}-${Routes.CompositeActivities}`,
        to: `${appPath}/${Routes.CompositeActivities}`,
      },
      {
        translationKey: 'layout.default.programs',
        name: `${ProtectedPath.APP}-${Routes.Programs}`,
        to: `${appPath}/${Routes.Programs}`,
      },
      {
        translationKey: 'layout.default.players',
        name: `${ProtectedPath.APP}-${Routes.Players}`,
        to: `${appPath}/${Routes.Players}`,
      },
    ],
  },
  {
    translationKey: 'layout.default.planning',
    name: `${ProtectedPath.APP}-planning`,
    child: [
      {
        translationKey: 'layout.default.calendar',
        name: `${ProtectedPath.APP}-${Routes.Calendar}`,
        to: `${appPath}/${Routes.Calendar}`,
      },
      {
        translationKey: 'layout.default.orders',
        name: `${ProtectedPath.APP}-${Routes.Orders}`,
        to: `${appPath}/${Routes.Orders}`,
      },
    ],
  },
  {
    translationKey: 'layout.default.settings',
    name: `${ProtectedPath.APP}-settings`,
    child: [
      {
        translationKey: 'layout.default.user',
        name: `${ProtectedPath.APP}-${Routes.User}`,
        to: `${appPath}/${Routes.User}`,
      },
      {
        translationKey: 'layout.default.tenant',
        name: `${ProtectedPath.APP}-${Routes.Tenant}`,
        to: `${appPath}/${Routes.Tenant}`,
      },
      {
        translationKey: 'layout.default.users',
        name: `${ProtectedPath.APP}-${Routes.Users}`,
        to: `${appPath}/${Routes.Users}`,
      },
    ],
  },
];
