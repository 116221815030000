import type { LoggedUserDto } from '@/api';

export const useProfile = (profile: LoggedUserDto | null) => {
  const getFullName = (p: LoggedUserDto | null) =>
    p ? `${p.firstName} ${p.lastName}` : '';
  const fullName = computed(() => getFullName(profile));

  const initials = computed(() => {
    if (profile) {
      return profile.firstName[0] + profile.lastName[0];
    }

    return '';
  });

  return {
    getFullName,
    fullName,
    initials,
  };
};
