<template>
  <v-menu min-width="200px" rounded>
    <template #activator="{ props: btnProps }">
      <Btn icon v-bind="btnProps" color="secondary" density="compact">
        <v-avatar
          data-cy="avatarDropdown"
          class="avatarDropdown__activator"
          color="primary"
          :transition="false"
        >
          {{ initials }}
        </v-avatar>
      </Btn>
    </template>
    <v-list nav>
      <v-list-item lines="three">
        <v-list-item-title>
          {{ fullName }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ profile?.email }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="mt-2" data-cy="avatarDropdownCenterName">
          {{ profile?.centerName }}
        </v-list-item-subtitle>
        <template #prepend>
          <v-avatar color="primary" :transition="false">
            {{ initials }}
          </v-avatar>
        </template>
      </v-list-item>
      <NuxtLink
        v-for="item in items"
        :key="item.labelKey"
        :to="localePath(String(item.to))"
        class="avatarDropdown__link"
      >
        <v-list-item
          :value="item"
          :data-cy="'avatarDropdown' + item.id"
          color="primary"
          @click="item.onClick"
        >
          <template #prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title>{{ t(item.labelKey) }}</v-list-item-title>
        </v-list-item>
      </NuxtLink>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import type { AvatarDropdownItem } from '@/types/avatarDropdown';
import { useUserStore } from '@/store/user';
import type { LoggedUserDto } from '@/api';
import { useProfile } from '@/composables/useProfile';

type Props = {
  dropdownItems?: AvatarDropdownItem[];
};

const props = withDefaults(defineProps<Props>(), {
  dropdownItems: () => [],
});

const { t } = useI18n();
const localePath = useLocalePath();
const userStore = useUserStore();

const logout = () => {
  userStore.logout();
};

const items: AvatarDropdownItem[] = [
  ...props.dropdownItems,
  {
    id: 'Logout',
    onClick: logout,
    labelKey: 'layout.default.logout',
    icon: 'i-mdi:logout',
  },
];

const profile: Ref<LoggedUserDto | null> = computed(() => userStore.loggedUser);

const { fullName, initials } = useProfile(profile.value);
</script>

<style lang="scss" scoped>
.avatarDropdown__activator {
  transition: none;
}
.avatarDropdown__link {
  text-decoration: none;
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
}
</style>
